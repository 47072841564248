import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { checkMobile } from "../Utils/LocationUtil";
import * as Widget from "../Components/Widget";
import * as Icon from "../Components/Icon";
import ChartPanel from "../Components/ChartPanel";
import HealthMeasureItem from "../Components/HealthMeasureItem";

class RecordDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: null
    };
  }

  componentDidMount() {
    if (this.props.profile) {
      this._fetchRecordDetail();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.profile && nextProps.profile) {
      this._fetchRecordDetail();
    }
  }

  render() {
    let { appActions, navActions, profile, mobileApp } = this.props;
    let { record } = this.state;

    return (
      <RecordDetailPage.Wrapper>
        <RecordDetailPage.BgPrimary />
        <RecordDetailPage.BgGrey />

        <RecordDetailPage.Content>
          <Widget.Row justify="space-between">
            <div
              className="button"
              onClick={() => {
                if (mobileApp) {
                  window.history.back();
                } else {
                  navActions.push("/profile");
                }
              }}
            >
              <Icon.ArrowBack color="black" size={26} />
              <div>看全部</div>
            </div>
            {!mobileApp && (
              <div
                className="button"
                onClick={() => 
                  navActions.push(`/edit-record?id=${record.id}`)
                }
              >
                <Icon.Edit color="black" size={26} />
              </div>
            )}
          </Widget.Row>

          {profile ? (
            <div>
              {!record && (
                <Widget.Center>
                  <Widget.Spinner />
                </Widget.Center>
              )}

              {record && <HealthMeasureItem record={record} detail={true} />}

              {record && (
                <Widget.Center>
                  <ChartPanel male={record.male} records={[record]} />
                </Widget.Center>
              )}
            </div>
          ) : (
            <Widget.Center>
              <Widget.Spinner />
            </Widget.Center>
          )}
        </RecordDetailPage.Content>
      </RecordDetailPage.Wrapper>
    );
  }

  _fetchRecordDetail = () => {
    let { appActions, queryParam } = this.props;
    appActions
      .getHealthRecord(queryParam.id)
      .then(record => {
        this.setState({ record });
      })
      .catch(err => {
        console.warn(err);
      });
  };

  static Wrapper = styled.div`
    position: relative;
    background-color: rgba(0, 0, 0, 0);
  `;

  static Content = styled.div`
    margin: 20px auto;
    max-width: 600px;
    min-height: 66vh;
    padding: 5px 15px;
    z-index: 1;
    background-color: white;
    position: relative;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    & .button {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }

    @media screen and (max-width: 800px) {
      width: 310px;
    }
  `;

  static BgPrimary = styled.div`
    position: fixed;
    background-color: teal;
    height: 50vh;
    width: 100vw;
    z-index: 0;
    top: 0;
    left: 0;
  `;

  static BgGrey = styled.div`
    position: fixed;
    background-color: #eee;
    height: 50vh;
    width: 100vw;
    z-index: 0;
    top: 50vh;
    left: 0;
  `;
}

export default withPage(
  connect(
    (state, ownProps) => ({
      mobileApp: checkMobile(ownProps.location),
      profile: Selectors.getLoginUser(state),
      queryParam: Selectors.getQueryParams(ownProps)
    }),
    ActionCreator
  )(RecordDetailPage)
);
